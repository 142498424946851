import React from "react"
import { useEffect } from 'react'

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.className = "ae-emd-script";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

const VideosSection = () => (
  <div className="bg-blue-200 w-full md:min-h-screen xl:h-auto px-4 pt-24 md:pt-40 pb-24">
    <div className="w-full max-w-2xl mx-auto">
      <h1 className="text-3xl md:text-5xl text-center text-gray-800 font-semibold mt-6">Our Videos</h1>
    </div>

    <div className='w-full mx-auto mt-16'>
      <div className="w-full md:p-8 mb-8 md:mb-0">
        <div className="tagembed-container" style={{ width: "100%", height: "100%", overflow: "auto" }}>
          <div className="tagembed-socialwall" data-wall-id="84590"></div>
          {useScript('https://widget.tagembed.com/embed.min.js')}
        </div>
      </div>
    </div>
  </div>
)

export default VideosSection
