import React from "react"

import Layout from "../components/layout/layout"
import VideosSection from "../components/sections/videos-section"
import Seonew from "../components/seo-new"

const ContactPage = () => (
  <Layout>
    <Seonew title="Contact Us" />
    <VideosSection />
  </Layout>
)

export default ContactPage
